import React, { Component } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    TimeScale,
    BarElement,
    BarController,
    Title,
    Tooltip,
    Legend } from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import 'chartjs-adapter-moment';


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarController,
    TimeScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

class SessionsGraph extends Component {
    state = { 
      label1: 'Duration [h]',
      label2: 'Sessions',
     }
    
    render() {
      const { unit } = this.props;
      var dataset1 = {
        type: 'bar',
        label: 'Duration [h]',
        data:[],
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      };
      var dataset2 = {
        type: 'bar',
        label: 'Sessions',
        data:[],
        backgroundColor: 'rgba(0, 99, 132, 0.5)',
        hidden: true,
      };

      this.props.graphSummaries?.forEach(summary => {
        var year = summary.date[0];
        var month = summary.date[1];
        var day = summary.date[2];
        var hour = summary.time[0];
        var dateStr = year;
        if (month) 
          dateStr += '-' + (month > 9 ? (month) : ('0' + month));
        else
          dateStr += '-01';
        if (day) 
          dateStr += '-' + (day > 9 ? (day) : ('0' + day));
        else 
          dateStr += '-01';
        if (hour) 
          dateStr += ' ' + (hour > 9 ? (hour) : ('0' + hour)) + ':00:00';

        dataset1.data.push({
          x: dateStr,
          y: summary.data.totalHours
        })
        dataset2.data.push({
          x: dateStr,
          y: summary.data.sessions
        })
      });
      dataset1.data.reverse();
      dataset2.data.reverse();

      const options = {
          responsive: true,
          plugins: {
            legend: {
              position: 'top',
            },
            title: {
              display: false,
              text: '',
            },
          },
          maintainAspectRatio: false,
          scales: {
            x: {
              type: 'time',
              display: true,
              offset: true,
              time: {
                unit: unit
              }
            }
          }
      };
      const data = {
        datasets: [dataset1, dataset2],
      };
      const a = '100vh';
      return (<div className="graphContainer">
          <Line height={"100%"}  options={options} data={data}/>
        </div>);
    }
}
 
export default SessionsGraph;