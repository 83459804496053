import React, { Component } from 'react';
import { DropTarget, DragSource } from 'react-dnd'

const types = {
    instance: 'instance',
}

const dropSource = {

    drop(props,monitor,component) {
        var server = monitor.getItem().publicDnsName;
        props.onConnect(server);
    },
    canDrop(props, monitor) {
        var item = monitor.getItem();
        if (!item.connectedDevice && item.publicDnsName && ((item.region === props.device.awshomeregion) || !item.region || !props.device.awshomeregion)) {
            return true;
        }
        else {
            return false;
        }
    }
}

const instanceSource = {
    beginDrag(props,monitor,component) {
        var currentInstance = props.availableInstances.find(instance => instance.publicDnsName === props.device.server)
        return currentInstance;
    },
}

class DeviceInstance extends React.Component {

    render() {
        const instanceId = this.props.device['connectedInstance'];
        var currentInstance;
        currentInstance = this.props.availableInstances.find(instance => instance.publicDnsName === this.props.device.server);
        if (currentInstance) { //server ok
            const { isOver, canDrop, isDragging, draggedItem } = this.props;
            var myClass = (isDragging && draggedItem && draggedItem.instanceId === currentInstance.instanceId) ? 'deviceInstanceContainer rounded-pill dragging ' : 'deviceInstanceContainer rounded-pill ';
            return this.props.connectDragSource(<div className={myClass}>
                <div className="deviceInstanceLogo">
                    <svg xmlns="http://www.w3.org/2000/svg"  height="2em" fill="currentColor" className="bi bi-hdd-stack" viewBox="0 0 16 16">
                    <path d="M14 10a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1h12zM2 9a2 2 0 0 0-2 2v1a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-1a2 2 0 0 0-2-2H2z"/>
                    <path d="M5 11.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm-2 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zM14 3a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12zM2 2a2 2 0 0 0-2 2v1a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2z"/>
                    <path d="M5 4.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm-2 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/>
                    </svg>
                </div>
                <div className="deviceInstanceInfo">
                    <div className="h6">{currentInstance.instanceId}</div>
                    <div className="text">{currentInstance.instanceType}</div>
                    <div className="h6">
                        {currentInstance.instanceState === 'getting ready' && (<div>
                        <div className="estTimeDev">READY IN {this.props.instancesTimerDict[currentInstance.instanceId] && (this.props.instancesTimerDict[currentInstance.instanceId].bootTime.minutes + 'min ')}
                        {this.props.instancesTimerDict[currentInstance.instanceId] && (this.props.instancesTimerDict[currentInstance.instanceId].bootTime.seconds + 's')}...</div>
                        </div>)}
                        {currentInstance.instanceState === 'ready' && <b>Ready</b>}
                        {currentInstance.instanceState !== 'ready' && currentInstance.instanceState !== 'getting ready' && currentInstance.instanceState}
                    </div>
                </div>
            </div>);
        }
        else {
            const { isOver, canDrop, isDragging, draggedItem } = this.props;
            var myClass = 'noDeviceInstanceContainer rounded-pill ';
            myClass += (isOver && canDrop) ? 'justDropDevice' : (!isOver && canDrop) ? 'canDropDevice' : '';
            return this.props.connectDropTarget(<div className={myClass}>
                <svg xmlns="http://www.w3.org/2000/svg" height="2.5em" fill="currentColor" className="bi bi-cloud-slash" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M3.112 5.112a3.125 3.125 0 0 0-.17.613C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13H11l-1-1H3.781C2.231 12 1 10.785 1 9.318c0-1.365 1.064-2.513 2.46-2.666l.446-.05v-.447c0-.075.006-.152.018-.231l-.812-.812zm2.55-1.45-.725-.725A5.512 5.512 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773a3.2 3.2 0 0 1-1.516 2.711l-.733-.733C14.498 11.378 15 10.626 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3c-.875 0-1.678.26-2.339.661z"/>
                <path d="m13.646 14.354-12-12 .708-.708 12 12-.707.707z"/>
                </svg>
                <div className="text">{(isOver && canDrop) ? 'Release to connect' : (isOver && !canDrop) ? 'You cannot connect this server' : canDrop ? 'Drop server here' : 'Drag server here'}</div>

            </div>);
        }
    }
}
    export default
    DragSource(types.instance,instanceSource,
        (connect, monitor) => ({
            connectDragSource: connect.dragSource(),
            connectDragPreview: connect.dragPreview(),
            isDragging: monitor.isDragging(),
        }),
    )(
        DropTarget(types.instance, dropSource,
            (connect, monitor) => {
                return {
                    connectDropTarget: connect.dropTarget(),
                    isOver: !!monitor.isOver(),
                    canDrop: !!monitor.canDrop(),
                    draggedItem: monitor.getItem(),
                };
            })(
            DeviceInstance));

