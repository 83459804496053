import './App.css';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import LiveOverview from './components/liveOverview';
import SessionsOverview from './components/sessionsOverview';

  class App extends React.Component {
    state = {
      backendUrl: undefined,
      currentTab: 'live',
      displayingInstances: 'ready',
      startButtonDisabled: false,
      settingsWindowClass: 'settingsWindow hidden',
      quantity: 1,
      devices: [],
      sessionsDevices: [],
      Instances: [],
      instancesReadyDict: {},
      instancesTimerDict: {},
      instancesBlockDict: {},
      availableInstances: [],
      lockedInstances: {},
      selectedInstance: undefined,
      images: [],
      image: undefined,
      countedImages: undefined,
      securityGroups: [],
      securityGroup: undefined,
      countedSecurityGroups: undefined,
      types: [],
      type: undefined,
      typesObjects: undefined,
      countedTypes: undefined,
      errorMessage: {
        title: undefined,
        code: undefined,
        message: undefined,
        service: undefined
      },
      errorContainerShow: 'errorsContainer', //showError,
      region: undefined,
      regions: ['us-west-2', 'eu-west-1'],
      autoterminationTime: undefined,
      autoterminationTimeOptions: [1],
      awsEnvironment: '',
    };
    displayError(error, title) {
      var errorMessage = {};
      if (error.serviceError) {
        errorMessage.code = error.serviceError.code;
        errorMessage.message = error.serviceError.message;
        errorMessage.service = error.serviceError.service;
      }
      else {
        errorMessage.code = undefined;
        errorMessage.message = error.message;
        errorMessage.service = undefined;
      }
      title ? errorMessage.title = title : errorMessage.title = '';
      this.setState({errorMessage: errorMessage, errorContainerShow: 'errorsContainer showError'});
      setTimeout(() => this.hideErrorTimeout(), 15000)
    }
    hideErrorTimeout() {
      this.setState({errorContainerShow: 'errorsContainer'})
    }
        
    handleError() {
        
      return async (response) => {
          if (!response.ok) {
              var err = new Error("problem");
              err.serviceError = await response.json();
      
              throw err;
          }
      
          return response;
      };
    };
    handleErrorClose() {
      this.setState({errorContainerShow: 'errorsContainer'})
    }
    async tryFetchDevices() {
      try {
          await this.fetchDevices();
      }
      catch (e) {
          console.log(e);
          this.displayError(e, 'Fetch Devices');
      }
      finally {
          setTimeout(() => {
            this.tryFetchDevices();
          }, 1000);
      }
  }

  toggleLock(id) {
    if (id in this.state.lockedInstances) {
      delete this.state.lockedInstances[id];
    }
    else {
      this.state.lockedInstances[id] = true;
    }

    this.setState({lockedInstances: this.state.lockedInstances});
  }

  async assignInstances() {
    const unassignedDevices = this.state.devices.filter(d => !d.connectedInstance && d.deviceState !== 'offline');
    
    if (!unassignedDevices.length) return;
    
    const unassignedInstances = this.state.Instances.filter(i => !i.connectedDevice && !(i.instanceId in this.state.lockedInstances) && i.instanceState === 'ready');
    
    if (!unassignedInstances.length) return;

    for (let i = 0 ; i < unassignedDevices.length && unassignedInstances.length; i++) {
      var addr = unassignedInstances.pop().publicDnsName;
      var id = unassignedDevices[i].deviceId;

      if (this.state.enableDefaultStartOptions) {
        try {
          await this.setInstanceParams(id, addr);
        }catch (e) {
          console.log(e);
        }
      }
      await this.handleConnect(id, addr);
    }
  }

  async fetchConfig() {
    const configPath = this.state.backendUrl + '/config/'
    const configResponse = await fetch(configPath)
    .then(this.handleError());
    const configJson = await configResponse.json();
    var defaults = configJson.defaultInstanceValues

    this.setState({image: defaults.image, securityGroup: defaults.securityGroup, type: defaults.type, autoterminationTime: configJson.autoterminationTime, autoterminationTimeOptions: configJson.autoterminationTimeOptions, awsEnvironment:configJson.Environment});
  }
  async fetchDefaultRegion() {

    const defaultRegionPath = this.state.backendUrl + '/getDefaultRegion/';
    const defaultRegionResponse = await fetch(defaultRegionPath)
    .then(this.handleError());
    const defaultRegionJson = await defaultRegionResponse.json();
    this.setState({region: defaultRegionJson.defaultRegion, regions: defaultRegionJson.regions})

  }

  async fetchDevices() {
      var devicesArray = [];
      const devicesUrl = this.state.backendUrl + '/readDevices';
      const devicesResponse = await fetch(devicesUrl)
      .then(this.handleError());
      const devicesJson = await devicesResponse.json();
      devicesArray =  devicesJson.map(device => {
          var newDevice = {};
          newDevice.deviceId = device.deviceId;
          var now = Date.now();
          if ((device.connected  ===  true) && (now - (device.lastupdate*1000) <= 32000)) {
              newDevice.deviceState = 'connected'
          }
          else if (now - (device.lastupdate*1000) <= 32000 ) {
              newDevice.deviceState = 'online'
          }
          else {
              newDevice.deviceState = 'offline'
          }
          newDevice.connectedInstance = undefined;
          this.state.Instances.map(instance => {
            if (device.server && instance && instance.publicDnsName && device.server  ===  instance.publicDnsName && 
              (instance.instanceState  ===  'ready' || instance.instanceState  ===  'getting ready') &&
              (!this.state.instancesBlockDict[instance.instanceId] || this.state.instancesBlockDict[instance.instanceId].block !== true)) {
              newDevice.connectedInstance = instance.instanceId;
            }
          })
          newDevice.server = device.server;
          newDevice.city = device.city;
          newDevice.country = device.country;
          newDevice.countryISO = device.countryISO;
          newDevice.screenshot = undefined;
          newDevice.connected = device.connected ? 'true': 'false';
          newDevice.deviceAddress = device.ip;
          newDevice.lastUpdate = new Date(device.lastupdate*1000).toLocaleString();
          newDevice.appState = undefined;
          newDevice.awshomeregion = device.awshomeregion;

          return newDevice;
      })
      devicesArray.sort((a,b) => {
        return a.deviceId - b.deviceId;
      });

      this.setState({devices: devicesArray});

      if (this.state.autoAssignInstances) await this.assignInstances();
  }
    async tryFetchInstances() {
      try {
          await this.fetchInstances();
      }
      catch (e) {
          console.log(e);
          this.displayError(e, 'Fetch Instances');
      }
      finally {
          setTimeout(() => {
            this.tryFetchInstances();
          }, 1000);
        }
    }
    async tryPingInstances() {
      try {
        await this.pingInstances(this.state.Instances);
      }
      catch (error) {
        console.log(error);
      }
      finally {
        setTimeout(() => {
          this.tryPingInstances();
        }, 1000);
      }
    }

    async fetchPing(address) {
      const pingUrl = this.state.backendUrl + '/ping/' + address;
      if (address) {
        const ping = await fetch(pingUrl)
        .then(this.handleError());;
        var status = await ping.json();
        if (status.status  ===  'active') {
          return true;
        }
        else {
          return false;
        }
      }
    }

    async pingInstances(instances) {

      instances = await Promise.all(instances.map(async instance => {
        if (instance.publicDnsName && (instance.instanceState  ===  'getting ready')) {
          const flag = await this.fetchPing(instance.publicDnsName);
          if (flag  ===  true) {
            this.state.instancesReadyDict[instance.instanceId] = {};
            this.state.instancesReadyDict[instance.instanceId].isReady = true;
          }
        }
        return instance; 
      }));
      this.setState({instancesReadyDict: this.state.instancesReadyDict});
    }
  
    async fetchInstances() {
        var instances = [];
        var availableInstances = [];
        const instancesUrl = this.state.backendUrl + '/instances';

        const amiJson = this.state.images;
        const instanceResponse = await fetch(instancesUrl)
        .then(this.handleError());
        const instancesJson = await instanceResponse.json();

        instancesJson.forEach(instancesGroup => {
          instancesGroup.forEach(instance => {
            if (this.state.instancesBlockDict[instance.InstanceId] && this.state.instancesBlockDict[instance.InstanceId].block === true) {
              //nothing
            }
            else {
              var newInstance = {
                instanceId: instance.InstanceId,
                instanceState: '',
                ami: instance.ImageId,
                instanceType: instance.InstanceType,
                connectedDevice: undefined,
                availabilityZone: instance.Placement.AvailabilityZone,
                region: instance.Placement.AvailabilityZone.slice(0, -1),
                launchTime: instance.LaunchTime,
                publicDnsName: instance.PublicDnsName ? instance.PublicDnsName : undefined
            };
            amiJson.map(image => {
                if (newInstance.ami  ===  image.ImageId) {
                    newInstance.ami = image.Name;
                }
            })
            this.state.devices.map(device => {
              if (device.server && instance.PublicDnsName && device.server  ===  instance.PublicDnsName) {
                newInstance.connectedDevice = device.deviceId;
              }
            })
            if (instance.State.Name  ===  'running' && this.state.instancesReadyDict[instance.InstanceId] && this.state.instancesReadyDict[instance.InstanceId].isReady  ===  true) {
              newInstance.instanceState = 'ready'
            }
            else if (instance.State.Name === 'running' || instance.State.Name === 'pending') {
              newInstance.instanceState = 'getting ready'
            }
            else {
              newInstance.instanceState = instance.State.Name;
            }
            instances.push(newInstance)
            }
          })
        })

        instances.sort((a,b) => {
          return a.launchTime - b.launchTime;
        });
        instances.reverse();
        this.setState({Instances: instances, availableInstances: availableInstances});
    }
    async fetchImages() {
      const imagesUrl = this.state.backendUrl + '/images';
      const imagesResponse = await fetch(imagesUrl)
      .then(this.handleError());
      const imagesJson = await imagesResponse.json();
      var allImages = [];

      imagesJson.forEach(images => {
        images.forEach(image => {
          allImages.push(image.Name);
        })
      })
      const _countedImages = {};
      allImages.forEach(element => { _countedImages[element] = (_countedImages[element] || 0) + 1; });

      var uniqueArray = allImages.filter(this.getUniqueArrayValues);
      uniqueArray.sort();
      this.setState({countedImages: _countedImages, images: uniqueArray})
      return imagesJson;
    }

    async fetchSessionsDevice(deviceId, since, until, offset) {
      if (!since || since === 'null') {
        since = 0;
      }
      if (!until || until === 'null') {
        until = 0;
      }
      if (!offset || offset === 'null') {
        offset = 0;
      }
      const url = this.state.backendUrl + '/deviceSessions/' + deviceId + '/' + since + '/' + until + '/' + offset;
      const response = await fetch(url)
      .then(this.handleError());
      const responseJson = await response.json();
      return responseJson;
    }

    async fetchSessions(since,until, offset) {
      if (!since || since === 'null')
        since = 0;
      if (!until || until === 'null') 
        until = 0;
      if (!offset || offset === 'null') 
        offset = 0;
      const url = this.state.backendUrl + '/sessions/' + since + '/' + until + '/' + offset;
      const response = await fetch(url)
      .then(this.handleError());
      const responseJson = await response.json();
      return responseJson;
    }

    async fetchSessionsSummary(since, until) {
      if (!since || since === 'null')
      since = 0;
      if (!until || until === 'null') 
        until = 0;
      const url = this.state.backendUrl + `/sessionsSummary/${since}/${until}`;
      const response = await fetch(url)
      .then(this.handleError());
      const responseJson = await response.json();
      return responseJson;
    }

    async fetchDeviceSummarySessions(deviceId, since, until) {
      if (!since || since === 'null')
      since = 0;
      if (!until || until === 'null') 
        until = 0;
      const url = `${this.state.backendUrl}/deviceSummarySessions/${deviceId}/${since}/${until}`
      const response = await fetch(url)
      .then(this.handleError());
      const responseJson = await response.json();
      return responseJson;
    }

    async fetchDevicesSessions() {
      const url = `${this.state.backendUrl}/devicesSessions`
      const response = await fetch(url)
      .then(this.handleError());
      const responseJson = await response.json();
      return responseJson;
    }

    async fetchSessionsSummaryByGroup(deviceId, groupId, since, until) {
      if (!since || since === 'null') 
        since = 0;
      if (!until || until === 'null') 
        until = 0;
      if (!deviceId || deviceId === 'null') 
        deviceId = 0;

      var url = `${this.state.backendUrl}/sessionsSummaryGroup/${groupId}/${since}/${until}/${deviceId}`
      
      const response = await fetch(url)
      .then(this.handleError());
      const responseJson = await response.json();
      return responseJson;
    }
    
    async createInstances() {
      const createUrl = this.state.backendUrl + '/create/' + this.state.image + '/' + this.state.quantity + '/' + this.state.securityGroup + '/' + this.state.type + '/' + this.state.region;
      try {
        this.setState({startButtonDisabled: true});
          const create = await fetch(createUrl)
          .then(this.handleError());
      }
      catch (error) {
          console.log(error);
          this.displayError(error, 'Create Instances');
      }
      finally {
        await this.fetchInstances();
        this.setState({startButtonDisabled: false});
      }
    }
    getInstanceById(instanceId) {
      return this.state.Instances.find(instance => instance.instanceId === instanceId);
    }
    async handleTerminate(id) {
      var instance = this.getInstanceById(id);
      instance.instanceState = 'shutting-down';
      instance.connectedDevice = undefined;
      var device = this.state.devices.find(device => device.connectedInstance === id);
      (device && (device.connectedInstance = undefined))
      this.state.instancesBlockDict[id] = {};
      this.state.instancesBlockDict[id].block = true;
      this.setState({Instances: this.state.Instances, devices: this.state.devices, instancesBlockDict: this.state.instancesBlockDict});

      const terminateUrl = this.state.backendUrl + '/terminate/'+ id + '/' + instance.region;

      try {
          const fetchTerminate = await fetch(terminateUrl)
          .then(this.handleError());
      }
      catch (error) {
          console.log(error);
          //this.displayError(error, 'Terminate');
      }
      finally {
        this.state.instancesBlockDict[id].block = false;
        this.setState({instancesBlockDict: this.state.instancesBlockDict});
      }
        
    }
    async handleReboot(id) {

      var instance = this.getInstanceById(id);

      const rebootUrl = this.state.backendUrl + '/reboot/' + id + '/' + instance.region;
      try {
          const fetchReboot = await fetch(rebootUrl)
          .then(this.handleError());
      }
      catch (error) {
        console.log(error);
        this.displayError(error, 'Reboot');

      }
      finally {
          //
      }
    }

    async fetchConnect(deviceId,server) {
      const connectUrl = this.state.backendUrl + '/connect/' + deviceId + '/' + server + '/' + this.state.region;
      const connect = await fetch(connectUrl)
      .then(this.handleError());
      return connect;
    }
    async handleDisconnect(deviceId) {
      try {
        await this.fetchConnect(deviceId,null)
        .then(this.handleError());;
      }
      catch (error) {
        console.log(error);
        this.displayError(error, 'Disconnect');
      }
      finally {
        //
      }
    }
    async handleConnect(deviceId,server) {
      var device = this.state.devices.find(device => device.deviceId === deviceId);
      device.server = server;
      var instance = this.state.Instances.find(instance => instance.publicDnsName === server);
      instance.connectedDevice = deviceId;

      this.state.instancesBlockDict[instance.instanceId] = {};
      this.state.instancesBlockDict[instance.instanceId].block = true;

      this.setState({devices: this.state.devices, Instances: this.state.Instances, instancesBlockDict: this.state.instancesBlockDict});
      try {
        await this.fetchConnect(deviceId,server)
        .then(this.handleError());
      }
      catch (error) {
        console.log(error)
        this.displayError(error, 'Connect');
      }
      finally {
        this.state.instancesBlockDict[instance.instanceId].block = false;
        this.setState({instancesBlockDict: this.state.instancesBlockDict})
      }
    }
    
    async fetchSecurityGroups() {
      const securityUrl = this.state.backendUrl + '/securityGroups';
      const securityResponse = await fetch(securityUrl);
      const securityJson = await securityResponse.json();
      var allSecurityGroups = [];

      securityJson.forEach(securityGroups => {
        securityGroups.forEach(securityGroup => {
          allSecurityGroups.push(securityGroup.GroupName);
        });
      });

      const _countedSecurityGroups = {};
      allSecurityGroups.forEach(element => { _countedSecurityGroups[element] = (_countedSecurityGroups[element] || 0) + 1; });

      var uniqueArray = allSecurityGroups.filter(this.getUniqueArrayValues);
      uniqueArray.sort();

      this.setState({countedSecurityGroups: _countedSecurityGroups, securityGroups: uniqueArray});
      return securityJson;
    }

    async fetchTypes() {
        const typesUrl = this.state.backendUrl + '/instanceTypes/';
        const typesResponse = await fetch(typesUrl);
        const typesJson = await typesResponse.json();
        var allTypes = [];
        var allTypesObjects = [];
        typesJson.forEach(typesGroup => {
            typesGroup.forEach(type => {
              allTypesObjects.push(type);
              allTypes.push(type.InstanceType);
            })
        })
        const _countedTypes = {};
        allTypes.forEach(element => { _countedTypes[element] = (_countedTypes[element] || 0) + 1; });

        var uniqueArray = allTypes.filter(this.getUniqueArrayValues);
        uniqueArray.sort();

        this.setState({countedTypes: _countedTypes, types: uniqueArray, typesObjects: allTypesObjects})
        return typesJson;
    }

    startMHD(options) {
      if (this.state.enableDefaultStartOptions) {
        options.noaudio = this.state.defaultNoaudio;
        options.room = this.state.defaultRoom;
      }
      var startUrl = `http://${options.server}:24000/start/${options.deviceid}?noaudio=${options.noaudio}&room=${options.room}`;
      var stopUrl = `http://${options.server}:24000/stop`;
      fetch(stopUrl)
      .then(() => fetch(startUrl));
    }

    startMhdAll() {
      const devicesConnected = this.state.devices.filter(d => d.connectedInstance && d.deviceState !== 'offline');

      devicesConnected.forEach(d => {
        this.startMHD({deviceid: d.deviceId, server: d.server});
      });
    }

    async setInstanceParams(deviceId, serverAddress) {
      var setUrl = `http://${serverAddress}:24000/setparams/${deviceId}?noaudio=${this.state.defaultNoaudio}&room=${this.state.defaultRoom}`;
      await fetch(setUrl);
    }

    initTimer(instanceId) {
      this.state.instancesTimerDict[instanceId] = {};
      this.state.instancesTimerDict[instanceId] = this.state.Instances.find(instance => instance.instanceId === instanceId);
      if (this.state.instancesTimerDict[instanceId]) {
        var start = new Date(this.state.instancesTimerDict[instanceId].launchTime).getTime()/1000;
      
        var estFinish = start + 180;
        var now = new Date().getTime()/1000;
        var diff = estFinish - now;
        this.state.instancesTimerDict[instanceId].bootTime = {};
        if (estFinish - now > 0) {
          this.state.instancesTimerDict[instanceId].bootTime.minutes = Math.floor((diff / 60) % 60);
          this.state.instancesTimerDict[instanceId].bootTime.seconds = Math.floor(diff % 60)
            setTimeout(() => this.initTimer(instanceId), 1000);
        }
        else {
          this.state.instancesTimerDict[instanceId].bootTime.minutes = 0;
          this.state.instancesTimerDict[instanceId].bootTime.seconds = 0;
        }
        this.setState({instancesTimerDict: this.state.instancesTimerDict})  
      }
 
  }
    handleSelectedInstance(event) {
      this.setState({selectedInstance: event.target.value});
    }
    
    handleAmount(event) {
      this.setState({quantity: event.target.value})
    }

    handleTypes(event) {
      this.setState({type: event.target.value});
    }

    handleRegion(event) {
      this.setState({region: event.target.value});
    }

    handleTabClick(event,tab) {
      var parent = event.currentTarget.parentElement;

      for (let i = 0; i < parent.children.length; i++) {
        if (parent.children[i].classList[parent.children[i].classList.length - 1] === 'filterButton-active') {
          parent.children[i].classList.remove('filterButton-active');
        }
      }
      this.setState({displayingInstances: tab})
      event.currentTarget.classList.add('filterButton-active');
    }

    async fetchBackendUrl() {
      
      const fileUrl = './backendServer.txt';
      await fetch(fileUrl)
      .then(response => response.text())
      .then(text => {
        this.setState({backendUrl: text})
        return;
      })
    }
    async componentDidMount() {
      await this.fetchBackendUrl();
      await this.fetchDefaultRegion();
      await this.fetchConfig();
      await this.fetchImages();
      this.tryFetchInstances();
      this.tryFetchDevices();
      this.fetchSecurityGroups();
      this.fetchTypes();
      this.tryPingInstances();
    }

    handleSettngsWindow(action) {
      if (action === 'open')
        this.setState({settingsWindowClass: 'settingsWindow'})
      else
        this.setState({settingsWindowClass: 'settingsWindow hidden'})
    }
    handleTabs(event) {
      if (event.target.attributes.name.value !== this.state.currentTab) {
        if (event.target.attributes.name.value === 'sessions') {
          this.fetchSessionsSummary();
          this.fetchSessions(0);
        }
        else if (event.target.attributes.name.value === 'live'){
        }
  
        this.setState({currentTab: event.target.attributes.name.value})
      }
    }
    getUniqueArrayValues(value, index, self) {
      return self.indexOf(value) === index;
    }

    updateDefaultSettings(image, securityGroup, type, region, autoterminationTime, awsEnvironment) {
      this.handleSettngsWindow('close');
      const { backendUrl } = this.state;
      fetch(backendUrl + '/setDefaultRegion/' + region);
      fetch(backendUrl + '/setConfig/' + image + '/' + securityGroup + '/' + type + '/' + region + '/' + autoterminationTime + '/' + awsEnvironment);
      this.setState({region: region, image: image, securityGroup: securityGroup, type: type, autoterminationTime: autoterminationTime, awsEnvironment: awsEnvironment})
    }

    renderAvailableRegionsForType(type) {

      const { regions, typesObjects } = this.state;

      var resultArray = [];

      if (typesObjects) {
        typesObjects.forEach(_type => {
          if (_type.InstanceType === type) {
            resultArray.push(_type.Location);
          }
        })

        if (resultArray.length < regions.length) {

          var result = resultArray.map(region => {
            if (resultArray.indexOf(region) !== 0 ) {
              return ', ' + region;
            }
            else {
              return 'Available in: ' + region;
            }
          })

          return <div>{result}</div>
        }
      }
    }
    render() {

      const { backendUrl, awsEnvironment } = this.state;
      let indicatorClassName = 'redBackground'

      if (backendUrl) {
        const backendUrlObj = new URL(backendUrl);
        if ((backendUrlObj.hostname === 'cloudxr-orchestration.medicalholodeck.com') && (awsEnvironment === 'prod')) {
          indicatorClassName = 'redBackground';
        }
        else if (awsEnvironment === 'prod') {
          indicatorClassName = 'yellowBackground';
        }
        else {
          indicatorClassName = 'blueBackground';
        }
      }

      const renderAmiOptions = this.state.images.map(image => {
        return (<option key={image} value={image}>{image}{(this.state.countedImages[image] === this.state.regions.length) ? '' : '\u26A0'}</option>)
      })
      const renderScGroupsOptions = this.state.securityGroups.map(group => {
        return (<option key={group} value={group}>{group}{(this.state.countedSecurityGroups[group] === this.state.regions.length) ? '' : '\u26A0'}</option>)
      })
      const renderTypesOptions = this.state.types.map(type => {
        return (<option key={type} value={type}>{type}{(this.state.countedTypes[type] === this.state.regions.length) ? '' : '\u26A0'}</option>)
      })
      const renderAutoterminationTimeOptions = this.state.autoterminationTimeOptions.map(timeOption => {
        return (<option key={timeOption + 'h'} value={timeOption}>{timeOption}h</option>)
      })
      const renderRegionOptions = this.state.regions.map(region => {
        return (<option key={region} value={region}>{region}</option>)
      })

      const renderLive = <LiveOverview 
        backendUrl = {this.state.backendUrl}
        displayingInstances = {this.state.displayingInstances}
        startButtonDisabled = {this.state.startButtonDisabled}
        settingsWindowClass = {this.state.settingsWindowClass}
        quantity = {this.state.quantity}
        devices = {this.state.devices}
        Instances = {this.state.Instances}
        instancesReadyDict = {this.state.instancesReadyDict}
        instancesTimerDict = {this.state.instancesTimerDict}
        instancesBlockDict = {this.state.instancesBlockDict}
        availableInstances = {this.state.availableInstances}
        lockedInstances = {this.state.lockedInstances}
        selectedInstance = {this.state.selectedInstance}
        images = {this.state.images}
        image = {this.state.image}
        countedImages = {this.state.countedImages}
        securityGroups = {this.state.securityGroups}
        securityGroup = {this.state.securityGroup}
        countedSecurityGroups = {this.state.countedSecurityGroups}
        types = {this.state.types}
        type = {this.state.type}
        typesObjects = {this.state.typesObjects}
        countedTypes = {this.state.countedTypes}
        errorMessage = {this.state.errorMessage}
        errorContainerShow = {this.state.errorContainerShow}
        region = {this.state.region}
        regions = {this.state.regions}
        autoterminationTime = {this.state.autoterminationTime}
        autoterminationTimeOptions = {this.state.autoterminationTimeOptions}
        awsEnvironment = {this.state.awsEnvironment}
        handleErrorClose = {() => this.handleErrorClose()}
        initTimer = {(id) => this.initTimer(id)}
        handleSelectedInstance = {(event) => this.handleSelectedInstance(event)}
        handleTerminate = {(id) => this.handleTerminate(id)}
        handleReboot = {(id) => this.handleReboot(id)}
        handleConnect = {(deviceId,server) => this.handleConnect(deviceId,server)}
        handleDisconnect = {(deviceId) => this.handleDisconnect(deviceId)}
        handleTabClick = {(event,tab) => this.handleTabClick(event,tab)}
        handleTypes = {(event) => this.handleTypes(event)}
        handleAmount = {(event) => this.handleAmount(event)}
        toggleLock = {(id) => this.toggleLock(id)}
        createInstances={() => this.createInstances()}
        renderAvailableRegionsForType = {(type) => this.renderAvailableRegionsForType(type)}
        fetchBackendUrl={async () => this.fetchBackendUrl()}
        fetchDefaultRegion={async () => this.fetchDefaultRegion()}
        fetchConfig={async () => this.fetchConfig()}
        handleSettngsWindow={(action) => this.handleSettngsWindow(action)}
      />
      
      const renderSessions = <SessionsOverview
          sessionsDevices = {this.state.sessionsDevices}
          fetchSessionsDevice = {(deviceId, since, until, offset) => this.fetchSessionsDevice(deviceId, since, until, offset)}
          fetchSessions = {(since,until, offset) => this.fetchSessions(since,until, offset)}
          fetchSessionsSummary = {(since, until) => this.fetchSessionsSummary(since, until)}
          fetchDeviceSummarySessions = {(deviceId, since, until) => this.fetchDeviceSummarySessions(deviceId, since, until)}
          fetchDevicesSessions={() => this.fetchDevicesSessions()}
          fetchSessionsSummaryByGroup={(deviceId, groupId, since, until) => this.fetchSessionsSummaryByGroup(deviceId, groupId, since, until)}
      />
      

      return (<DndProvider backend={HTML5Backend}>
        <div className="App" style={{display: 'flex', flexDirection:'row', height: '100vh'}}>
          <div id="environmentIndicator" className={indicatorClassName}>{this.state.awsEnvironment}
            <div className="switchPageContainer" onClick={(event) => this.handleTabs(event)}>
              <div className={this.state.currentTab === 'live' ? "switchPageButton switchPageButton-left switchPageButton-active" : "switchPageButton switchPageButton-left"} name="live" >Live</div>
              <div className={this.state.currentTab === 'sessions' ? "switchPageButton switchPageButton-right switchPageButton-active" : "switchPageButton switchPageButton-right"} name="sessions">Sessions</div>
            </div>
          </div>
          {this.state.currentTab === 'sessions' ? renderSessions : renderLive}
        </div>
        </DndProvider>
      );
    }
}
 
export default App;

