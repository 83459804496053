import React, { Component } from 'react';
import Device from './device';

class Devices extends React.Component {
    render() {
        
        const displayedDevices = this.props.devices.map((device) => {
            return (
            <Device
                key={device.deviceId}
                onDisconnect={(clickEvent) => this.props.onDisconnect(device.deviceId)}
                onStartMHD={(options) => this.props.onStartMHD({...options, server: device.server, deviceid: device.deviceId})}
                onConnect={(id,server) => this.props.onConnect(id,server)}
                onReboot={() => this.props.onReboot(device.connectedInstance)}
                onTerminate={() => this.props.onTerminate(device.connectedInstance)}
                onSelect={(selectEvent) => this.props.onSelect(selectEvent)}
                selectedInstance={this.props.selectedInstance}
                availableInstances={this.props.availableInstances}
                instancesReadyDict={this.props.instancesReadyDict}
                instancesTimerDict={this.props.instancesTimerDict}
                value={device}
                backendUrl={this.props.backendUrl}
                enableDefaultStartOptions={this.props.enableDefaultStartOptions}
                />
                
            );
            });
        return (<div className="devicesContainer">
        <div className="tableTitle h3">CloudXR Clients</div>
                    {displayedDevices}
        </div>);
    }
}
 
export default Devices;