import React, { Component } from 'react';

class Counter extends Component {
    state = {
        value: this.props.value,
        style: {
            color: 'black'
        }
    };


    handleIncrement = () => {
        this.setState({ value: this.state.value + 1 })
    }


    render() {
        return (
            <div>
                <span style={this.state.style} className={this.getBadgeClasses()}>{this.formatCount()}</span>
                <button onClick={this.handleIncrement} className="btn btn-secondary btn-sm">Increment</button>
                <buttnon onClick={this.props.onDelete} className="btn btn-danger btn-sm m-2">Delete</buttnon>
            </div>
        );
    }

    getBadgeClasses() {
        let classes = "badge m-2 badge-";
        classes += (this.state.value === 0) ? "warning" : "primary";
        return classes;
    }

    formatCount() {
        const { value: count } = this.state;
        return count === 0 ? 'Zero' : count;
    }
}

export default Counter;