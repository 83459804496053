import React, { Component } from 'react';
import Devices from './devices';
import Instances from './instances';
import Settings from './settings';
import { v4 as uuidv4 } from 'uuid';

class LiveOverview extends Component {
    state = {
        autoAssignInstances: false,
        enableDefaultStartOptions: false,
        defaultNoaudio: true,
        defaultRoom: "",
    };

    selectInstances() {
        if (this.props.displayingInstances === 'ready') {
          return this.props.Instances.filter(instance => (instance.instanceState  ===  'ready' || instance.instanceState  ===  'getting ready') && !instance.connectedDevice)
        }
        else if (this.props.displayingInstances === 'inUse') {
          return this.props.Instances.filter(instance => (instance.instanceState  ===  'ready' || instance.instanceState  ===  'getting ready') && instance.connectedDevice)
        }
        else if (this.props.displayingInstances === 'stopped') {
          return this.props.Instances.filter(instance => instance.instanceState !== 'ready' && instance.instanceState !== 'getting ready')
        }
      }

    render() {

        const { backendUrl, awsEnvironment } = this.props;
        let indicatorClassName = 'redBackground'
  
        if (backendUrl) {
          const backendUrlObj = new URL(backendUrl);
          if ((backendUrlObj.hostname === 'cloudxr-orchestration.medicalholodeck.com') && (awsEnvironment === 'prod')) {
            indicatorClassName = 'redBackground';
          }
          else if (awsEnvironment === 'prod') {
            indicatorClassName = 'yellowBackground';
          }
          else {
            indicatorClassName = 'blueBackground';
          }
        }
  
        const renderAmiOptions = this.props.images.map(image => {
          return (<option key={image} value={image}>{image}{(this.props.countedImages[image] === this.props.regions.length) ? '' : '\u26A0'}</option>)
        })
        const renderScGroupsOptions = this.props.securityGroups.map(group => {
          return (<option key={group} value={group}>{group}{(this.props.countedSecurityGroups[group] === this.props.regions.length) ? '' : '\u26A0'}</option>)
        })
        const renderTypesOptions = this.props.types.map(type => {
          return (<option key={type} value={type}>{type}{(this.props.countedTypes[type] === this.props.regions.length) ? '' : '\u26A0'}</option>)
        })
        const renderAutoterminationTimeOptions = this.props.autoterminationTimeOptions.map(timeOption => {
          return (<option key={timeOption + 'h'} value={timeOption}>{timeOption}h</option>)
        })
        const renderRegionOptions = this.props.regions.map(region => {
          return (<option key={region} value={region}>{region}</option>)
        })
  
        return (
            <div className="liveOverview">
                <Settings
                handleSettngsWindow={(action) => this.props.handleSettngsWindow(action)}
                updateDefaultSettings={(image, securityGroup, type, region, autoterminationTime, awsEnvironment) => this.props.updateDefaultSettings(image, securityGroup, type, region, autoterminationTime, awsEnvironment)}
                renderRegionOptions={renderRegionOptions}
                renderAmiOptions={renderAmiOptions}
                renderScGroupsOptions={renderScGroupsOptions}
                renderTypesOptions={renderTypesOptions}
                renderAutoterminationTimeOptions={renderAutoterminationTimeOptions}
                region={this.props.region}
                image={this.props.image}
                securityGroup={this.props.securityGroup}
                type={this.props.type}
                autoterminationTime={this.props.autoterminationTime}
                awsEnvironment={this.props.awsEnvironment}
                settingsWindowClass={this.props.settingsWindowClass}
                fetchBackendUrl={async () => this.props.fetchBackendUrl()}
                fetchDefaultRegion={async () => this.props.fetchDefaultRegion()}
                fetchConfig={async () => this.props.fetchConfig()}
                renderAvailableRegionsForType = {(type) => this.props.renderAvailableRegionsForType(type)}
                />
                <Instances 
                createInstances={() => this.props.createInstances()}
                onTerminate={(id) => this.props.handleTerminate(id)}
                onReboot={(id) => this.props.handleReboot(id)}
                onLockInstance={(id) => this.props.toggleLock(id)}
                lockedInstances={this.props.lockedInstances}
                onChangeAmount={(event) => this.props.handleAmount(event)}
                onChangeType={(event) => this.props.handleTypes(event)}
                onTabClick={(event,tab) => this.props.handleTabClick(event,tab)}
                onDisconnect={(deviceId) => this.props.handleDisconnect(deviceId)}
                devices={this.props.devices}
                Instances={this.props.Instances}
                InstancesToDisplay={this.selectInstances()}
                displayingInstances={this.props.displayingInstances}
                quantity={this.props.quantity}
                images={this.props.images}
                image={this.props.image}
                securityGroups={this.props.securityGroups}
                securityGroup={this.props.securityGroup}
                types={this.props.types}
                type={this.props.type}
                countedTypes={this.props.countedTypes}
                instancesTimerDict={this.props.instancesTimerDict}
                initTimer={(id) => this.props.initTimer(id)}
                startButtonDisabled={this.props.startButtonDisabled}
                showSettings={(action) => this.props.handleSettngsWindow(action)}
                backendUrl={this.props.backendUrl}
                autoterminationTime={this.props.autoterminationTime}
                handleRegion={(event) => this.props.handleRegion(event)}
                region={this.props.region}
                regions={this.props.regions}
                />
                <div style={{flex:1, display:'flex', flexDirection:'column', margin:'15px'}}>
                    <div style={{display:'grid', gridTemplateColumns: '1fr 1fr', columnGap: '15px'}}>
                        <div className="card" style={{textAlign: 'left'}}>
                            <div className="card-body">
                            <h5 className="card-title">Automator</h5>
                            <label><input type="checkbox" checked={this.state.autoAssignInstances}  onChange={e => this.setState({autoAssignInstances: e.target.checked})} />Auto assign instances</label><br/>
                            <label><input type="checkbox" checked={this.state.enableDefaultStartOptions}  onChange={e => this.setState({enableDefaultStartOptions: e.target.checked})} />Enable Default Start Options</label><br/>
                            </div>          
                        </div>
                        <div className="card" style={{textAlign: 'left'}}>
                            <div className="card-body">
                            <h5 className="card-title">Default Start Options</h5>
                            <label><input type="checkbox" checked={this.state.defaultNoaudio}  onChange={e => this.setState({defaultNoaudio: e.target.checked})} /> No audio</label><br/>
                            <div style={{display:'flex', margin:'0.5em 0',  alignItems:'center', flexDirection:'row'}}>
                                <label htmlFor="defaultroom" >Room</label><input style={{margin:'0 0.5em', flex:1}} id="defaultroom" type="text" value={this.state.defaultRoom} onInput={e => this.setState({defaultRoom: e.target.value})} className="form-control" /> <button className="btn col-sm-3 btn-outline-secondary" onClick={() => this.setState({defaultRoom: uuidv4()})}>Generate</button>
                            </div>
                            <button onClick={() => this.props.startMhdAll()} className="btn btn-outline-primary btn-sm">Start MHD</button>
                            </div>          
                        </div>
                    </div>
                <div style={{flex:1, overflow:'auto'}}>
                <Devices
                    devices={this.props.devices}
                    availableInstances={this.props.Instances.filter(instance => instance.instanceState === 'getting ready' || instance.instanceState  ===  'ready')}
                    instancesReadyDict={this.props.instancesReadyDict}
                    onStartMHD={(options) => this.props.startMHD(options)}
                    onDisconnect={(deviceId) => this.props.handleDisconnect(deviceId)}
                    onConnect={(deviceId,server) => this.props.handleConnect(deviceId,server)}
                    onReboot={(id) => this.props.handleReboot(id)}
                    onTerminate={(id) => this.props.handleTerminate(id)}
                    selectedInstance={this.props.selectedInstance}
                    enableDefaultStartOptions={this.props.enableDefaultStartOptions}
                    onSelect={(event) => this.props.handleSelectedInstance(event)}
                    instancesTimerDict={this.props.instancesTimerDict}
                    backendUrl={this.props.backendUrl}
                />
                <div className={this.props.errorContainerShow}>
                    <div className="errorTitleRow">
                        <div className="errorTitle">
                        ERROR: {this.props.errorMessage.title}
                        </div>
                        <div onClick={() => this.props.handleErrorClose()} className="errorCloseButton btn-close"></div>
                    </div>
                    <div className="errorMessage">
                    {'Message: ' + this.props.errorMessage.message}<br/>
                    {this.props.errorMessage.code ? 'CODE: ' + this.props.errorMessage.code : undefined}<br/>
                    {this.props.errorMessage.service ? 'Service: ' + this.props.errorMessage.service : undefined}
                    </div>
                </div>
            </div>
                </div>
            </div>
        );
      }
}

export default LiveOverview;