import React from 'react';

class Settings extends React.Component {

    state = {
        region: undefined,
        image: undefined,
        securityGroup: undefined,
        type: undefined,
        autoterminationTime: undefined,
        awsEnvironment: "",
    }

    handleRegion(event) {
        this.setState({region: event.target.value})
    }

    handleImages(event) {
        this.setState({image: event.target.value})
    }

    handleSecurityGroups(event) {
        this.setState({securityGroup: event.target.value})
    }

    handleTypes(event) {
        this.setState({type: event.target.value});
    }

    handleAutoterminationTime(event) {
        this.setState({autoterminationTime: event.target.value});
    }

    handleTag(event) {
        this.setState({awsEnvironment: event.target.value});
    }

    handleValue(name) {
        return (event) => this.setState({[name]: event.target.value});
    }

    setStateToProps() {
        this.setState({region: this.props.region, image: this.props.image, securityGroup: this.props.securityGroup, type: this.props.type, autoterminationTime: this.props.autoterminationTime, awsEnvironment: this.props.awsEnvironment})
    }

    async componentDidMount() {
        await this.props.fetchBackendUrl();
        await this.props.fetchDefaultRegion();
        await this.props.fetchConfig();
        this.setStateToProps();
    }

    render() {
        return(<div className={this.props.settingsWindowClass}>
            <div className="settingsWindowTitle h3">Settings <button onClick={() => {this.props.handleSettngsWindow('close'); this.setStateToProps();}} className="settingsWindowCloseButton btn-close"></button></div>
            <div className="settingsWindowContent">
            <div className="d.block">
                Region<br/>
                <select onChange={this.handleValue('region')} className="settingsSelect" value={this.state.region}>
                {this.props.renderRegionOptions}
                </select>
            </div>
            <div className="d.block">
                Image<br/>
                <select onChange={(event) => this.handleImages(event)} className="settingsSelect" value={this.state.image}>
                {this.props.renderAmiOptions}
                </select>
            </div>
            <div className="d.block">
                Security Group<br/>
                <select onChange={(event) => this.handleSecurityGroups(event)} className="settingsSelect" value={this.state.securityGroup}>
                {this.props.renderScGroupsOptions}
                </select>
            </div>
            <div className="d.block">
                Type<br/>
                <select onChange={(event) => this.handleTypes(event)} className="settingsSelect" value={this.state.type}>
                {this.props.renderTypesOptions}
                </select>
                {this.props.renderAvailableRegionsForType(this.state.type)}
            </div>
            <div className="d.block">
                Auto Terminate After<br/>
                <select onChange={(event) => this.handleAutoterminationTime(event)} className="settingsSelect" value={this.state.autoterminationTime}>
                {this.props.renderAutoterminationTimeOptions}
                </select>
            </div>
            <div className="d.block">
                Environment<br/>
                <input type="text" className="settingsInputText" onChange={(event) => this.handleTag(event)} value={this.state.awsEnvironment}></input>
            </div>
            <div className="d.block">
                <button onClick={() => this.props.updateDefaultSettings(this.state.image, this.state.securityGroup, this.state.type, this.state.region, this.state.autoterminationTime, this.state.awsEnvironment)} className="btn btn-outline-primary">SAVE</button>
            </div>
            </div>
        </div>)
    }

}

export default Settings;