import React, { Component } from 'react';
import DeviceInstance from './deviceInstance';
import Flag from 'react-flags';

class Device extends React.Component {
    state = {
        screenshotSrc: '',
        extended: false,
        noaudio: true,
        room: ''
    }

    handleDeviceClick(event) {
        var a;
        var instanceDetails = event.currentTarget.parentElement.children[1];
        var arrow = event.currentTarget.children[0];
        if (instanceDetails.style.display === 'flex') {
            a = false;
            instanceDetails.style.display = 'none';
            arrow.classList.remove('rotate');
        }
        else {
            a = true;
            instanceDetails.style.display = 'flex';
            arrow.classList.add('rotate');
        }
        this.setState({extended: a});
    }
    async getScreenshotSrc() {
        if (this.state.extended) {
            var server = this.props.value['server'];
            var src = this.props.backendUrl + '/screenshot/' + server + '?r=' + Math.random();
            this.setState({screenshotSrc: src});
        }
        setTimeout(() => this.getScreenshotSrc(), 1000);

    }
    printScreenshot() {
        return (<a href={this.state.screenshotSrc} rel="noreferrer" target="_blank">
            <img className="screenshot" src={this.state.screenshotSrc} alt="Screenshot"/>
        </a>)
    }
    componentDidMount() {
        this.getScreenshotSrc();
    }

    render() {
        const currentInstance = this.props.availableInstances.find(instance => instance.publicDnsName === this.props.value.server);
        return (
            <div className="device">
                <div className="instanceTitleRow">
                    <div className="deviceIcon">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-headset-vr" viewBox="0 0 16 16">
                        <path d="M8 1.248c1.857 0 3.526.641 4.65 1.794a4.978 4.978 0 0 1 2.518 1.09C13.907 1.482 11.295 0 8 0 4.75 0 2.12 1.48.844 4.122a4.979 4.979 0 0 1 2.289-1.047C4.236 1.872 5.974 1.248 8 1.248z"/>
                        <path d="M12 12a3.988 3.988 0 0 1-2.786-1.13l-.002-.002a1.612 1.612 0 0 0-.276-.167A2.164 2.164 0 0 0 8 10.5c-.414 0-.729.103-.935.201a1.612 1.612 0 0 0-.277.167l-.002.002A4 4 0 1 1 4 4h8a4 4 0 0 1 0 8z"/>
                        </svg>
                    </div>
                    <div className="deviceInfo">
                        <div className="h6">{this.props.value['deviceId']}</div>
                        <div className="text">Oculus Quest 2</div>
                        <div className="text">{this.props.value['deviceAddress']}</div>
                        <div className="text">{this.props.value.awshomeregion ? this.props.value.awshomeregion : 'homeregion undefined'}</div>
                        <div className="text textCountry">{this.props.value['city'] + ', ' + this.props.value['country'] + ' '}
                            <Flag
                                basePath='/img/flags'
                                name={this.props.value['countryISO'] || ""}
                                country={this.props.value['countryISO'] || ""}
                                format="png"
                                pngSize={16}
                                shiny={false}
                            />
                        
                        </div>
                        <div className="text"><b>{this.props.value['deviceState'] === 'online' ? 'Online' : 'Offline'}</b></div>
                    </div>
                    <div className="deviceServerContainer">
                            <DeviceInstance
                            key={this.props.value['server']}
                            availableInstances={this.props.availableInstances}
                            instancesReadyDict={this.props.instancesReadyDict}
                            instancesTimerDict={this.props.instancesTimerDict}
                            device={this.props.value}
                            onConnect={(server) => this.props.onConnect(this.props.value.deviceId,server)}
                            />
                    
                    </div>
                </div>
                <div className="deviceDetails">
                    <div className="leftContainer">
                        {currentInstance && <><div className="h6"><b>Image Name</b></div>
                        <div className="text">{currentInstance.ami}</div></>}
                        <div className="h6"><b>DNS</b></div>
                        <div className="text">{(this.props.value['server'] && this.props.value.connectedInstance )? this.props.value['server'] : 'No server'}</div>
                        <div className="h6"><b>Start Options</b></div>
                        <label><input type="checkbox" disabled={this.props.enableDefaultStartOptions} checked={this.state.noaudio}  onChange={e => this.setState({noaudio: e.target.checked})} /> No audio</label><br/>
                        <label>Room <input type="text" disabled={this.props.enableDefaultStartOptions} value={this.state.room} onInput={e => this.setState({room: e.target.value})} /></label><br/>
                        {this.props.value['server'] && this.props.value.connectedInstance && 
                        <>
                            <p><button onClick={() => this.props.onStartMHD({noaudio: this.state.noaudio, room: this.state.room})} className="btn btn-outline-primary btn-sm">Start MHD</button></p>
                            <button onClick={() => this.props.onDisconnect(this.props.value.deviceId)} className="btn btn-outline-warning btn-sm">Disconnect</button>
                            <button onClick={() => this.props.onReboot()} className="btn btn-outline-secondary btn-sm">Reboot</button>
                            <button onClick={() => this.props.onTerminate(this.props.value.connectedInstance)} className="btn btn-outline-danger btn-sm">Terminate</button>
                        </>
                        }
                    </div>
                    <div className="rightContainer">
                        {this.props.value && this.props.value.server && this.props.value.connectedInstance && currentInstance && currentInstance.instanceState === 'ready' && this.printScreenshot()}
                        <div>
                            {this.props.value['server'] && this.props.value.connectedInstance && currentInstance && currentInstance.instanceState === 'ready' && 
                                <a className="link-primary" target="_blank" rel="noreferrer" href={'https://' + this.props.value['server'] + ':8443?authToken=J7hUBh28APMUtPkqmnuQvkCsCW6Mu24ryrqxkPhHemDtHg6pdFJrpheTrdLpmYHy'}>Open console</a>
                            }
                        </div>
                    </div>
                </div>
                <div onClick={(event) => this.handleDeviceClick(event)} className="deviceExtend">
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                    </svg>
                </div>
                
            </div>
        )
                
    
}}
 
export default Device;