import React, { Component } from 'react';
import SessionDevice from './sessionsDevice'
import Session from './session'
import SessionsGraph from './sessionsGraph'
class SessionsOverview  extends Component { 
    state = {
        devices: undefined,
        selectedDevice: undefined,
        sessions: undefined,
        sessionsAmount: undefined,
        totalTime: undefined,
        deviceIdFilterValue: '',
        sessionDateMinFilterValue: "",
        sessionDateMaxFilterValue: "",
        graphGroup: 1,
        graphGroupOptions: ['year', 'month', 'day', 'hour'],
        graphSummaries: undefined,
        unit: 'month'
    }

    async handleClickDevice(event, device) {
        if (this.state.selectedDevice !== device) {
            const {sessionDateMinFilterValue, sessionDateMaxFilterValue, selectedDevice} = this.state;
            var minFilter = (sessionDateMinFilterValue === "" && 'null') || (new Date(sessionDateMinFilterValue).getTime() / 1000);
            var maxFilter = (sessionDateMaxFilterValue === "" && 'null') || ((new Date(sessionDateMaxFilterValue).getTime() / 1000) + 86400);
            this.updateSessions(device.deviceId, minFilter, maxFilter, 0);
            this.updateSessionsSummary(device.deviceId, minFilter, maxFilter);
            this.setState({selectedDevice: device});
            this.updateGraphSummaries(device.deviceId, this.state.graphGroup, minFilter, maxFilter);
        }
    }

    getSortedSessions(sessions) {
        return sessions.sort((a,b) => (a.info.firstupdate > b.info.firstupdate) ? -1 : ((a.info.firstupdate < b.info.firstupdate) ? 1 : 0));
    }

    handleDeviceIdFilter(event) {
        this.setState({deviceIdFilterValue: event.target.value});
    }

    handleSessionMinDateFilter(event) {
        this.setState({sessionDateMinFilterValue: event.target.value}, () => {
            this.handleSearch();
        });
    }

    handleSessionMaxDateFilter(event) {
        this.setState({sessionDateMaxFilterValue: event.target.value}, () => {
            this.handleSearch();
        });
    }

    async handleClearFiltersBtn() {

        const {sessionDateMaxFilterValue, sessionDateMinFilterValue, selectedDevice} = this.state;
        this.updateSessions(null, 0,0,0);
        this.updateSessionsSummary(null, 0,0);
        this.updateGraphSummaries(null, 2, 0, 0);
        this.setState({sessionDateMaxFilterValue: "", sessionDateMinFilterValue: "", unit: this.state.graphGroupOptions[1], selectedDevice: undefined, deviceIdFilterValue: "", graphGroup: 1});
    }

    async handleSearch() {
        const {sessionDateMaxFilterValue, sessionDateMinFilterValue, selectedDevice} = this.state;
        var minFilter = (sessionDateMinFilterValue === "" && 'null') || (new Date(sessionDateMinFilterValue).getTime() / 1000);
        var maxFilter = (sessionDateMaxFilterValue === "" && 'null') || ((new Date(sessionDateMaxFilterValue).getTime() / 1000) + 86400);
        if (selectedDevice) {
            this.updateSessions(selectedDevice.deviceId, minFilter, maxFilter, 0);
            this.updateSessionsSummary(selectedDevice.deviceId, minFilter, maxFilter);
            this.updateGraphSummaries(selectedDevice.deviceId, this.state.graphGroup, minFilter, maxFilter);

        }
        else {
            this.updateSessions(null, minFilter, maxFilter, 0);
            this.updateSessionsSummary(null, minFilter, maxFilter);
            this.updateGraphSummaries(null, this.state.graphGroup, minFilter, maxFilter);

        }
    }

    updateSessionstime(sessions) {
        var ctr = 0;
        sessions.forEach(session => {
            ctr += session.data.sessionDurationSeconds;
        })
        this.setState({totalTime: ctr})
    }

    async updateDevices() {
        var devices = await this.props.fetchDevicesSessions();
        var newDevices = [];
        devices.forEach(sessionDevice => {
            newDevices.push({deviceId: sessionDevice.info.deviceId, sessions: sessionDevice.data.sessions,totalSeconds: sessionDevice.data.totalSeconds})
        });
        this.setState({devices: newDevices});
    }

    async updateSessions(deviceId, since, until, offset) {
        var sessions;
        if (deviceId) 
            sessions = await this.props.fetchSessionsDevice(deviceId, since, until, offset);
        else 
            sessions = await this.props.fetchSessions(since,until,offset);
        var sortedSessions = this.getSortedSessions(sessions);
        this.setState({sessions: sortedSessions});
    }

    getSessionsGrouped(sessions) {
        if (!sessions) return;
        var grouped = {};
        sessions.forEach(session => {
            var groupdate = this.getGroupByUnit(session.info.firstupdate);
            if (!grouped[groupdate]) {
                grouped[groupdate] = [];
            }
            grouped[groupdate].push(session)
        });
        return grouped;
    }

    getGroupByUnit(unixTimestamp) {
        var date = new Date(unixTimestamp*1000);
        var year = date.getFullYear();
        var month = date.getMonth(); // month is indexed from 0
        var day = date.getDate();
        var hour = date.getHours();
        var result;
        switch (this.state.unit) {
            case 'year':
                result = year;
                break;
            case 'month':
                result = `${year}-${month > 9 ? month : '0' + month}`;
                break;
            case 'day':
                result = `${year}-${month > 9 ? month : '0' + month}-${day > 9 ? day : '0' + day}`;
                break;
            case 'hour':
                result = `${year}-${month > 9 ? month : '0' + month}-${day > 9 ? day : '0' + day} ${hour}:00`;
                break;
            default:
                result = year;
                break;
        }
        return result; 
    }

    async updateGraphSummaries(deviceId, groupId, since, until) {
        groupId++;
        var summaries = await this.props.fetchSessionsSummaryByGroup(deviceId, groupId, since, until);
        var graphSummaries = [];
        if (deviceId) {
            summaries.forEach(summary => {
                var newObj = {
                    deviceId: summary.key[0],
                    date: [],
                    time: [],
                    data: summary.data
                };
                summary.key.forEach(sumkey => {
                    if (summary.key.indexOf(sumkey) > 0) {
                        if (summary.key.indexOf(sumkey) > 3)
                            newObj.time.push(sumkey);
                        else {
                            newObj.date.push(sumkey);
                        }
                    }
                });
                graphSummaries.push(newObj);
            })
        }
        else {
            summaries.forEach(summary => {
                var newObj = {
                    deviceId: null,
                    date: [],
                    time: [],
                    data: summary.data
                };
                summary.key.forEach(sumkey => {
                    if (summary.key.indexOf(sumkey) > 2)
                        newObj.time.push(sumkey);
                    else {
                        newObj.date.push(sumkey);
                    }
                });
                graphSummaries.push(newObj);
            })
        }
        this.setState({graphSummaries: graphSummaries, unit: this.state.graphGroupOptions[groupId - 1]});
    }

    async updateSessionsSummary(deviceId, since, until) {
        var summary;
        if (deviceId) 
            summary = await this.props.fetchDeviceSummarySessions(deviceId, since, until);
        else
            summary = await this.props.fetchSessionsSummary(since,until);

        if (summary[0])
            this.setState({sessionsAmount: summary[0].data.sessions, totalTime: summary[0].data.totalSeconds});
        else 
            this.setState({sessionsAmount: 0, totalTime: 0});
    }

    async componentDidMount() {
        await this.updateDevices();
        await this.updateSessions(null, 0,0,0);
        await this.updateSessionsSummary(null, 0,0);
        await this.updateGraphSummaries(null, this.state.graphGroup,0,0);
        this.setRangeToCurrentYear();
    }

    async handleLoadMore() {
        const {selectedDevice, sessionDateMinFilterValue, sessionDateMaxFilterValue, sessions} = this.state;
        var minFilter = (sessionDateMinFilterValue === "" && 'null') || (new Date(sessionDateMinFilterValue).getTime() / 1000);
        var maxFilter = (sessionDateMaxFilterValue === "" && 'null') || ((new Date(sessionDateMaxFilterValue).getTime() / 1000) + 86400);
        var moreSessions;
        if (selectedDevice) {
            moreSessions = await this.props.fetchSessionsDevice(selectedDevice.deviceId, minFilter, maxFilter, sessions.length);
        }
        else {
            moreSessions = await this.props.fetchSessions(minFilter, maxFilter, sessions.length);
        }
        var newSessions = sessions
        moreSessions.forEach(session => {
            newSessions.push(session);
        })
        this.setState({sessions: newSessions});
    }


    getDateString(date) {
        return (date.getFullYear() + '-' + (date.getMonth() > 8 ?  (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '-' + (date.getDate() > 9 ? date.getDate() : ('0' + date.getDate())));
    }

    setRangeToCurrentYear() {
        var nowDate = new Date();
        var firstDay = new Date(nowDate.getFullYear(), 0, 1);
        var lastDay = new Date(nowDate.getFullYear(), 11, 31);
        var firstDayString = this.getDateString(firstDay);
        var lastDayString = this.getDateString(lastDay);
        this.setState({sessionDateMinFilterValue: firstDayString, sessionDateMaxFilterValue: lastDayString}, () => {
            this.handleSearch();
        });
    }

    handleCurrentMonthButton() {
        var nowDate = new Date();
        var firstDay = new Date(nowDate.getFullYear(), nowDate.getMonth(), 1);
        var lastDay = new Date(nowDate.getFullYear(), nowDate.getMonth() + 1, 0);
        var firstDayString = this.getDateString(firstDay);
        var lastDayString = this.getDateString(lastDay);
        this.setState({sessionDateMinFilterValue: firstDayString, sessionDateMaxFilterValue: lastDayString}, () => {
            this.handleSearch();
        });
    }
    handleLastMonthButton() {
        var nowDate = new Date();
        var firstDay = new Date(nowDate.getFullYear(), nowDate.getMonth() - 1, 1);
        var lastDay = new Date(nowDate.getFullYear(), nowDate.getMonth() , 0);
        var firstDayString = this.getDateString(firstDay);
        var lastDayString = this.getDateString(lastDay);
        this.setState({sessionDateMinFilterValue: firstDayString, sessionDateMaxFilterValue: lastDayString}, () => {
            this.handleSearch();
        });
    }
    handleGraphScale(event) {
        this.setState({graphGroup: event.target.value}, () => {
            this.handleSearch();
        });
    }

    renderSession(sessions) {
        var sessionsArray = sessions.map((session) => {
            <Session
                key = {"session_" + session.data.instanceId + ":" + session.info.lastupdate}
                session={session}
                selectedDevice={this.state.selectedDevice}
            />
            
        })
        return sessionsArray;

    }

    render() {
        const filteredDevices = this.state.devices?.filter(device => device.deviceId?.toLowerCase().includes(this.state.deviceIdFilterValue?.toLowerCase()));
        const renderDevice = filteredDevices?.map(device => {
            return (
                <SessionDevice
                    key={"sessionDev_" + device.deviceId}
                    device={device}
                    handleClickDevice={(event, device) => this.handleClickDevice(event,device)}
                    selectedDevice={this.state.selectedDevice}
                />
            );
        });
        var sessionsGrouped = this.getSessionsGrouped(this.state.sessions);
        var renderSessionsGroup = sessionsGrouped && Object.keys(sessionsGrouped).map(key=> {
            var sessions = sessionsGrouped[key];
            const renderSessions = sessions && sessions.map(session => {
                return (
                    <Session
                        key={"session_" + session.data.instanceId + ":" + session.info.lastupdate}
                        session={session}
                        selectedDevice={this.state.selectedDevice}
                    />
                )
            })
            return (
                <div className="sessionGroupContainer">
                    <p className="sessionGroupTitle h6">{key}</p>
                    {renderSessions}
                </div>
            );
        })

        const renderGraphGroupOptions = this.state.graphGroupOptions.map(option => {
            return (<option key={'graph_' + option} value={this.state.graphGroupOptions.indexOf(option)}>{option}</option>)
        })

        const renderLoadMoreButton = (
        <div className="session loadMoreBtnContainer" onClick={() => this.handleLoadMore()}>
            <div className="loadMoreBtn">
                <svg xmlns="http://www.w3.org/2000/svg" width="3em" fill="currentColor" className="bi bi-plus-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                </svg>
            </div>
            <p className="h6">Load more</p>
        </div>)
        var totalHours = Math.floor(this.state.totalTime / 3600);
        var totalMinutes = Math.floor(this.state.totalTime / 60) - totalHours * 60;
        var totalSeconds = this.state.totalTime - (totalMinutes * 60) - totalHours * 3600;

        return <div className="sessionsOverview">
        <div className="sessionLeftContainer">
            <div className="sessionsDevicesTabTitleRow">
                <div className="sessionsTabTitle h2">Devices</div>
                <div className="sessionsDevicesSearchbar border--black-bottom">
                    <input type="text" placeholder="Device ID" value={this.state.deviceIdFilterValue} onChange={(event) => this.handleDeviceIdFilter(event)}></input>
                </div>
            </div>
            <div className="sessionDevicesContainer">
                {renderDevice}
            </div> 
        </div>
        <div className="sessionRightContainer">
            <div className="sessionsTabTitleRow">
                <div>
                    <div className="sessionsTabTitle h2">
                        {this.state.selectedDevice ? ("Session: " + this.state.selectedDevice.deviceId) : "All sessions"}
                    </div>
                    <div className="sessionsTabSummaryRow border--black-bottom flex-end">
                        <div className="sessionsDevicesSearchbar flex-end">
                            <div className="filterElement">
                                <a>Start date</a>
                                <input type="date" value={this.state.sessionDateMinFilterValue} onChange={(event) => this.handleSessionMinDateFilter(event)}></input>
                            </div>

                            <div className="filterElement">
                                <a>End date</a>
                                <input type="date" value={this.state.sessionDateMaxFilterValue} onChange={(event) => this.handleSessionMaxDateFilter(event)}></input>
                            </div>

                            <div className="filterElement">
                                <a>Graph scale</a>
                                <select value={this.state.graphGroup} className="" onChange={(event) => this.handleGraphScale(event)}>
                                    {renderGraphGroupOptions}
                                </select>
                            </div>

                            <div className="filterElement">
                                <button className="btn btn-outline-success btn-sm monthBtn" onClick={() => this.handleCurrentMonthButton()}>Current month</button>
                            </div>

                            <div className="filterElement">
                                <button className="btn btn-outline-success btn-sm monthBtn" onClick={() => this.handleLastMonthButton()}>Last month</button>
                            </div>

                            <div className="filterElement">
                                <button className="btn btn-outline-success btn-sm monthBtn" onClick={() => this.setRangeToCurrentYear()}>Current year</button>
                            </div>

                            <div className="filterElement">
                                <button className="btn btn-outline-secondary btn-sm min-width-7" onClick={() => this.handleSearch()}>Search</button>
                            </div>

                            <div className="filterElement">
                                <button className="btn btn-outline-danger btn-sm min-width-7" onClick={() => this.handleClearFiltersBtn()}>Clear filters</button>
                            </div>

                        </div>
                        <div className="sessionsSummaryContainer">
                            <div>Number of Sessions: {this.state.sessionsAmount}</div>
                            <div>Total time: {totalHours > 0 ? (totalHours + 'h') : ''} {((totalMinutes > 0) || (totalHours > 0)) ? (totalMinutes + 'm') : ''} {totalSeconds}s</div>


                        </div>
                    </div>
                </div>
                    <SessionsGraph
                        key="graph"
                        sessions={this.state.sessions}
                        graphSummaries={this.state.graphSummaries}
                        unit={this.state.unit}
                    />

            </div>
            <div className="sessionDevicesContainer sessionsContainer">
                {this.state.sessions ? renderSessionsGroup : ''}
                {(this.state.sessions) && ((this.state.sessions.length < this.state.sessionsAmount) ? renderLoadMoreButton : '')}
            </div>
        </div>
    </div>}
}

export default SessionsOverview;