import React, { Component } from 'react';
import { DropTarget } from 'react-dnd'

const types = {
    instance: 'instance',
}
const dropSource = {
    drop(props,monitor,component) {
        var droppedInstance = monitor.getItem();
        if (droppedInstance.connectedDevice) {
            var a = window.confirm("This instance is in use. Are you sure you want to terminate this instance?");
            if (a) {
                props.onTerminate(droppedInstance.instanceId);
                
            }
        }
        else {
            props.onTerminate(droppedInstance.instanceId);
        }
        
        
    },
    hover(props, monitor, component) {
        //
    },
}
class TerminateArea extends React.Component {
    render() {
        const {isOver, canDrop} = this.props;
        var myClass = 'terminateInstancesContainer '
        myClass += (isOver && canDrop) ? 'justDrop' : (!isOver && canDrop) ? 'canDrop' : ''
        return this.props.connectDropTarget(<div className={myClass}>
            <svg xmlns="http://www.w3.org/2000/svg" height="3.5em" fill="currentColor" className="bi bi-trash2" viewBox="0 0 16 16">
                <path d="M14 3a.702.702 0 0 1-.037.225l-1.684 10.104A2 2 0 0 1 10.305 15H5.694a2 2 0 0 1-1.973-1.671L2.037 3.225A.703.703 0 0 1 2 3c0-1.105 2.686-2 6-2s6 .895 6 2zM3.215 4.207l1.493 8.957a1 1 0 0 0 .986.836h4.612a1 1 0 0 0 .986-.836l1.493-8.957C11.69 4.689 9.954 5 8 5c-1.954 0-3.69-.311-4.785-.793z"/>
            </svg><br/>
            Drop server to stop
        </div>)
    }
}
 
export default DropTarget(types.instance, dropSource,
    (connect, monitor) => {
        return {
            connectDropTarget: connect.dropTarget(),
            isOver: !!monitor.isOver(),
            canDrop: (monitor.getItem() && (monitor.getItem().instanceState === 'ready' || monitor.getItem().instanceState === 'getting ready' ))? !!monitor.canDrop() : false,
            draggedItem: monitor.getItem(),
        };
    }
)(TerminateArea);




