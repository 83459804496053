import React, { Component } from 'react';
import {DragSource} from 'react-dnd';

const types = {
    instance: 'instance',
}
const instanceSource = {
    beginDrag(props,monitor,component) {
        const item = props.value;
        return item
    },
    isDragging(props,monitor) {
        return monitor.getItem();
    },
    canDrag(props, monitor) {
        return props.canDrag;
    }
}
var globalInstance;

class Instance extends Component {
    state = {
        terminateTimeLeft: {
            hours: 0,
            minutes: 0,
            seconds: 0,
        },
        displayTerminationTime: undefined,
        className: 'instance',
        extended: false,
        screenshotSrc: undefined,
    }
    async getScreenshotSrc() {
        if (this.state.extended) {
            var server = this.props.value.publicDnsName;
            var src = this.props.backendUrl + '/screenshot/' + server + '?r=' + Math.random();
            this.setState({screenshotSrc: src});
        }
        setTimeout(() => this.getScreenshotSrc(), 1000);

    }
    printScreenshot() {
        return (<a href={this.state.screenshotSrc} rel="noreferrer" target="_blank">
            <img className="screenshot" src={this.state.screenshotSrc} alt="Screenshot"/>
        </a>)
    }
    async tryUpdateTerminateTimeLeft() {
        try {
            await this.updateTerminateTimeLeft();
        }
        catch (e) {
            console.log(e);
        }
        finally {
            setTimeout(() => {
              this.tryUpdateTerminateTimeLeft();
            }, 500);
          }
      }

    async updateTerminateTimeLeft() {
        if (this.props.value.publicDnsName) {

            var msleft;
            var now = Date.now();

            const url = 'http://'+ this.props.value.publicDnsName + ':24000/status';
            const result = await fetch(url)
            .then(async response => {
                if (!response.ok) {
                    var err = new Error("problem");
                    err.serviceError = await response.json();
                    throw err;
                }
                return response;
            })
            const instanceStatus = await result.json();
            if (instanceStatus.lastDeviceUpdate == 0) {

                var startTime = new Date(this.props.value['launchTime']).getTime();
                var endTime = startTime + (this.props.autoterminationTime * 60 * 60 * 1000);
                msleft = endTime - now;
                this.setState({displayTerminationTime: true});
            }
            else if (Math.abs(instanceStatus.lastDeviceUpdate - now) > 5000){

                var startTime = instanceStatus.lastDeviceUpdate;
                var endTime = startTime + (this.props.autoterminationTime * 60 * 60 * 1000);
                msleft = endTime - now;
                this.setState({displayTerminationTime: true});
                
            }
            else {
                this.setState({displayTerminationTime: false});
            }

            var seconds = Math.floor((msleft / 1000) % 60),
            minutes = Math.floor((msleft / (1000 * 60)) % 60),
            hours = Math.floor((msleft / (1000 * 60 * 60)) % 24);
            if (msleft < 0) {
                hours = '00';
                minutes = '00';
                seconds = '00';

            }
            else {
                hours = (hours < 10) ? "0" + hours : hours;
                minutes = (minutes < 10) ? "0" + minutes : minutes;
                seconds = (seconds < 10) ? "0" + seconds : seconds;
            }

            var newTimeLeft = {
                hours: hours, minutes: minutes, seconds: seconds
            }
            this.setState({terminateTimeLeft: newTimeLeft })
        }
    }

    handleInstanceClick(event) {
        var flag;
        var instanceDetails = event.currentTarget.parentElement.children[1];
        var arrow = event.currentTarget.children[0];
        var info = event.currentTarget.parentElement.children[0].children[1];
        if (instanceDetails.style.display === 'flex') {
            instanceDetails.style.display = 'none';
            info.style.borderRight = '1px solid black';
            arrow.classList.remove('rotate')
            flag = false;
        }
        else {
            instanceDetails.style.display = 'flex';
            flag = true;
            info.style.borderRight = 'none';
            arrow.classList.add('rotate')
        }
        this.setState({extended: flag})
    }

    componentDidMount() {
        this.tryUpdateTerminateTimeLeft();
        this.props.initTimer(this.props.value.instanceId);
        this.props ? globalInstance = this.props.value : globalInstance = undefined;
        this.getScreenshotSrc();
    }

    render() {
        const connectedDevice = this.props.devices.find(device => device.deviceId === this.props.value.connectedDevice);
        const { isDragging, draggedItem, value} = this.props;
            
        
        var myClass = ((isDragging && draggedItem.instanceId === this.props.id) || !value.publicDnsName) ? 'instance dragging' : 'instance'
        return this.props.connectDragSource(<div className={myClass}>
            <div className="instanceTitleRow">
                <div className="instanceIcon">
                <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" className="bi bi-hdd-stack" viewBox="0 0 16 16">
                <path d="M14 10a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1h12zM2 9a2 2 0 0 0-2 2v1a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-1a2 2 0 0 0-2-2H2z"/>
                <path d="M5 11.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm-2 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zM14 3a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12zM2 2a2 2 0 0 0-2 2v1a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2z"/>
                <path d="M5 4.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm-2 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/>
                </svg>
                </div>
                <div className="instanceInfo">
                    <div className="h6">{this.props.value['instanceId']}</div>
                    <div className="text">{this.props.value['region']}</div>
                    <div className="text">{this.props.value['instanceType']}{this.props.locked ? ' (locked)':''}</div>
                    <div className="h6">
                        {this.props.value['instanceState'] === 'getting ready' && (!this.props.value.publicDnsName && 'Waiting for DNS' || this.props.value.publicDnsName && 'Starting')  }
                        {this.props.value['instanceState'] === 'ready' && <b>Ready</b>}
                        {this.props.value['instanceState'] !== 'ready' && this.props.value['instanceState'] !== 'getting ready' && this.props.value['instanceState']}
                    </div>
                </div>
                <div className="instanceState">
                    {this.props.value['instanceState'] === 'ready' && 
                        <svg xmlns="http://www.w3.org/2000/svg" height="2.5em" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                        </svg>
                    }
                    {this.props.value['instanceState'] === 'getting ready' &&<div>
                        <div className="spinner-border spinnerSize"  role="status">
                        <span className="visually-hidden">Loading...</span>
                        </div>
                        <div className="estTime">{this.props.instancesTimerDict[this.props.value.instanceId] && (this.props.instancesTimerDict[this.props.value.instanceId].bootTime.minutes + 'min ')}
                        {this.props.instancesTimerDict[this.props.value.instanceId] && (this.props.instancesTimerDict[this.props.value.instanceId].bootTime.seconds + 's')}</div>
                        </div>
                    }
                    {(this.props.value['instanceState'] === 'terminated' || this.props.value['instanceState'] === 'shutting-down') && ''
                    }
                </div>
            </div>
                <div className="instanceDetails">
                    <div className="instanceDetailsInfo">
                        <div className="h6">Launched</div>
                        <div className="text">{this.props.value['launchTime']}</div>
                        {this.state.displayTerminationTime && this.props.value['instanceState'] === 'ready' && <>
                            <div className="h6">Terminate in:</div>
                            <div className="text">{this.state.terminateTimeLeft.hours + ':' + this.state.terminateTimeLeft.minutes + ':' + this.state.terminateTimeLeft.seconds}</div>
                        </>}
                        <div className="h6">Image Name</div>
                        <div className="text">{this.props.value.ami}</div>
                        <button onClick={() => this.props.onLockInstance(this.props.value.instanceId)} className="btn btn-outline-secondary btn-sm">{this.props.locked ? 'Unlock' : 'Lock'} Instance</button><br/>
                        {this.props.value['instanceState'] === 'ready' && 
                        <div className="text-start">
                            <div className="h6">DNS</div>
                            <div className="text">{this.props.value['publicDnsName']}</div>
                            <a className="link-primary" target="_blank" href={'https://' + this.props.value['publicDnsName'] + ':8443?authToken=J7hUBh28APMUtPkqmnuQvkCsCW6Mu24ryrqxkPhHemDtHg6pdFJrpheTrdLpmYHy'}>Open console</a><br/>
                            {connectedDevice && <button onClick={() => this.props.onDisconnect(connectedDevice.deviceId)} className="btn btn-outline-warning btn-sm">Disconnect</button>}
                            <button onClick={() => this.props.onReboot()} className="btn btn-outline-secondary btn-sm">Reboot</button>
                            <button onClick={() => this.props.onTerminate(this.props.value.instanceId)} className="btn btn-outline-danger btn-sm">Terminate</button>
                        </div>
                        }
                    </div>
                    <div className="instanceDetailsScreenshot">
                        {this.props.value.publicDnsName && this.props.value.instanceState == 'ready' && this.printScreenshot()}
                    </div>
                </div>
                <div onClick={(event) => this.handleInstanceClick(event)} className="instanceExtend">
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                    </svg>
                </div>
            
        </div>);
    }
}
export default DragSource(types.instance,instanceSource,
    (connect, monitor) => ({
        connectDragSource: connect.dragSource(),
        connectDragPreview: connect.dragPreview(),
        isDragging: monitor.isDragging(),
        instance: globalInstance,
        draggedItem: monitor.getItem(),
    }),

)(Instance);