import React, { Component } from 'react';
import Instance from './instance';
import TerminateArea from './terminateArea';

class Instances extends React.Component {
    state = {
        localInstances: []
    }

    async componentDidMount() {
        await this.setState({image: this.props.defaultImage, securityGroup: this.props.defaultSecurityGroup, availabilityZone: this.props.defaultAvailabilityZone, type: this.props.defaultType});
    }


    displayImages() {
        return this.props.images.map(image => {
            return (<option key={image.ImageId} value={image.ImageId}>{image.Name}</option>)
        })
    }

    displaySecurityGroups() {
        return this.props.securityGroups.map(securityGroup => {
            return (<option key={securityGroup.GroupId} value={securityGroup.GroupId}>{securityGroup.GroupName}</option>)
        })
    }

    displayTypes() {
        return this.props.types.map(type => {
            return (<option key={type} value={type}>{type}{(this.props.countedTypes[type] === this.props.regions.length) ? '' : '\u26A0'}</option>)
        })
    }

    render() {
        const displayedInstances = this.props.InstancesToDisplay.map((instance) => {
            return (
            <Instance
                locked={instance.instanceId in this.props.lockedInstances}
                onLockInstance={() => this.props.onLockInstance(instance.instanceId)}
                canDrag={(instance.instanceState === 'ready' || instance.instanceState === 'getting ready') ? true : false}
                key={instance.instanceId}
                id={instance.instanceId}
                onReboot={() => this.props.onReboot(instance.instanceId)}
                onTerminate={() => this.props.onTerminate(instance.instanceId)}
                onDisconnect={(deviceID) => this.props.onDisconnect(deviceID)}
                devices={this.props.devices}
                value={instance} 
                instancesTimerDict={this.props.instancesTimerDict}
                initTimer={(id) => this.props.initTimer(id)}
                backendUrl={this.props.backendUrl}
                autoterminationTime={this.props.autoterminationTime}
                />
            );
        });
        const renderRegionOptions = this.props.regions.map(region => {
            return (<option key={region} value={region}>{region}</option>)
          })
        return (<div className="serversContainer">
            <div>
                <div className="tableTitle h2 renderServersTitle">Render Servers
                <button onClick={() => this.props.showSettings('open')} className="btn btn-outline-secondary">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-gear" viewBox="0 0 16 16">
                        <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z"/>
                        <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z"/>
                    </svg>
                    </button>
                </div>
                <div className="createServerOptions">
                <div className="createOption">
                        <select className="form-select" value={this.props.region} onChange={this.props.handleRegion}>
                            {renderRegionOptions}
                        </select>
                    </div>
                    <div className="createOption">
                        <select className="form-select" value={this.props.quantity} onChange={this.props.onChangeAmount}>
                            <option value='1'>1</option>
                            <option value='2'>2</option>
                            <option value='3'>3</option>
                            <option value='4'>4</option>
                            <option value='5'>5</option>
                            <option value='6'>6</option>
                            <option value='7'>7</option>
                            <option value='8'>8</option>
                            <option value='9'>9</option>
                            <option value='10'>10</option>
                        </select>
                    </div>
                    <div className="createOption">
                        <select className="form-select" name="instanceType" value={this.props.type} onChange={this.props.onChangeType} id="instanceTypeSelect">{this.displayTypes()}</select>
                    </div>
                    <button className="startButton rounded-pill" disabled={this.props.startButtonDisabled} onClick={this.props.createInstances}>Start {this.props.quantity} {this.props.quantity > 1 ? 'servers' : 'server'}</button>
                </div>

                <div className="filterButtonsContainer">
                    <div onClick={(event) => this.props.onTabClick(event, 'ready')} className="filterButton rounded-pill filterButton-active">Ready
                        <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-dark">
                        {this.props.Instances.filter(instance => (instance.instanceState === 'ready' || instance.instanceState === 'getting ready') && !instance.connectedDevice).length}
                        </span>
                    </div>
                    <div onClick={(event) => this.props.onTabClick(event, 'inUse')} className="filterButton rounded-pill">In Use
                        <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-dark">
                        {this.props.Instances.filter(instance => (instance.instanceState === 'ready' || instance.instanceState === 'getting ready') && instance.connectedDevice).length}
                        </span>
                    </div>
                    <div onClick={(event) => this.props.onTabClick(event, 'stopped')} className="filterButton rounded-pill">Stopped
                        <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-dark">
                        {this.props.Instances.filter(instance => instance.instanceState !== 'ready' && instance.instanceState !== 'getting ready').length}
                        </span>
                    </div>
                </div>
            </div>

            <div className="instancesList">
                {displayedInstances}
            </div>
            <div>
            <TerminateArea
                key='terminateArea'
                onTerminate={(instanceId) => this.props.onTerminate(instanceId)}
            />
            </div>
        </div>);
    }
}
 
export default Instances;